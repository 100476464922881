<template>
  <div class="hello">
    <h1>Welcome to the Homestead!</h1>
    <h3>There's not much here currently, but don't you worry!<br>This place will be bustling before you can hatch an egg!</h3>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
</style>
